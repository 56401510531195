// Full Calendar Plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import Vue from "vue";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from "@vue/composition-api";
import store from "@/store";

export default function userCalendar() {
  // Use toast
  const toast = useToast();
  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null);

  // ------------------------------------------------
  // calendarApi
  // ------------------------------------------------
  let calendarApi = null;
  onMounted(() => {
    calendarApi = refCalendar.value.getApi();
  });

  // ------------------------------------------------
  // calendars
  // ------------------------------------------------
  const calendarsColor = {
    Business: "primary",
    Holiday: "success",
    Personal: "danger",
    Family: "warning",
    ETC: "info",
  };

  // ------------------------------------------------
  // event
  // ------------------------------------------------
  const blankEvent = {
    title: "",
    start: "",
    color: "",
    url: "",
    status: "",
    colorName: "",
    user_id:'',
    assign_to:'',
    assigner_approve:'',
    replay: {
      approved_duration: "",
      approved_duration_type: "",
      body: "",
    },
    extendedProps: {
      body: "",
      dur: "",
      duration_type: "",
      leave_type_id: "",
    },
  };
  const event = ref(JSON.parse(JSON.stringify(blankEvent)));
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent));
  };

  // *===========================================================================---*
  // *--------- Calendar API Function/Utils --------------------------------------------*
  // Template Future Update: We might move this utils function in its own file
  // *===========================================================================---*

  // ------------------------------------------------
  // (UI) addEventInCalendar
  // ? This is useless because this just add event in calendar and not in our data
  // * If we try to call it on new event then callback & try to toggle from calendar we get two events => One from UI and one from data
  // ------------------------------------------------
  // const addEventInCalendar = eventData => {
  //   toast({
  //     component: ToastificationContent,
  //     position: 'bottom-right',
  //     props: {
  //       title: 'Event Added',
  //       icon: 'Checdurcon',
  //       variant: 'success',
  //     },
  //   })
  //   calendarApi.addEvent(eventData)
  // }

  // ------------------------------------------------
  // (UI) updateEventInCalendar
  // ------------------------------------------------
  const updateEventInCalendar = (updatedEventData, propsToUpdate) => {
    toast({
      component: ToastificationContent,
      props: {
        title: "Event Updated",
        icon: "Checdurcon",
        variant: "success",
      },
    });

    const existingEvent = calendarApi.getEventById(updatedEventData.id);
    //console.log("existingEvent", existingEvent);
    // --- Set event properties except date related ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setProp
    // dateRelatedProps => ['start', 'end', 'allDay']
    // eslint-disable-next-line no-plusplus
    // for (let index = 0; index < propsToUpdate.length; index++) {
    //   const propName = propsToUpdate[index]
    //   existingEvent.setProp(propName, updatedEventData[propName])
    // }

    // --- Set date related props ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setDates
    existingEvent.setDates(updatedEventData.start, updatedEventData.end);

    // --- Set event's extendedProps ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
    // eslint-disable-next-line no-plusplus
  };

  // ------------------------------------------------
  // (UI) removeEventInCalendar
  // ------------------------------------------------
  const removeEventInCalendar = (eventId) => {
    toast({
      component: ToastificationContent,
      props: {
        title: "Event Removed",
        icon: "TrashIcon",
        variant: "danger",
      },
    });
    calendarApi.getEventById(eventId).remove();
  };

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  const grabEventDataFromEventApi = (eventApi) => {
    //console.log("eventApi", eventApi);
    const {
      id,
      title,
      start,
      color,
     
      colorName,
     
      extendedProps: { dur, duration_type,assign_to, body, assigner_approve,    user_id,leave_type_id ,status,},

      // eslint-disable-next-line object-curly-newline
    } = eventApi;
    //console.log("ggg", eventApi);
    return {
     
      colorName,
      id,
      title,
     
      start,
      color,
      extendedProps: { dur, duration_type, assign_to,body, leave_type_id,    user_id,assigner_approve,status, },
    };
  };

  // ------------------------------------------------
  // addEvent
  // ------------------------------------------------
  const addEvent = (eventData) => {
    let user = JSON.parse(localStorage.getItem("userData"));

    let data = {
      status: 0,

      user_id: user.id,
      start: eventData.start,
     
      body: eventData.body,
      duration: eventData.duration,
      duration_type: eventData.duration_type,
      assign_to: eventData.assign_to,
      leave_type_id: eventData.leave_type_id,
    };

    store
      .dispatch("calendar/addEvent", data)
      .then(() => {
        // eslint-disable-next-line no-use-before-define
        Vue.swal({
          title: "تم الاضافة   بنجاح",

          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        refetchEvents();
      })
      .catch((error) => {
        Vue.swal({
          title: `${error.response.data.message}`,
          icon: "error",

          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      });
  };

  // ------------------------------------------------
  // updateEvent
  // ------------------------------------------------
  const updateEvent = (eventData, replay) => {
    let user = JSON.parse(localStorage.getItem("userData"));
    let dataReplay;
    if (replay.id == 1) {
      dataReplay = {
        approved_duration_type: parseInt(replay.duration_type),
        
        approved_duration:parseInt( replay.duration),
        leave_id: eventData.id,
        replied_by: user.id,
      };
    } else {
      dataReplay = {
        body: replay.body,
        leave_id: eventData.id,
        replied_by: user.id,
      };
    }

    let eventupdate = {
      status: replay.id,
      id: eventData.id,
    };
    store
      .dispatch("calendar/updateEventReplay", dataReplay)
      .then((response) => {
        store.dispatch("calendar/updateEvent", eventupdate);
        const updatedEvent = response.data;
        //console.log("updatedEvent", updatedEvent);
        refetchEvents();
        Vue.swal({
          title: "تم الرد   بنجاح",

          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        // const propsToUpdate = [
        //   "id",
        //   "title",
        //   "color",
        //   "body",
        //   "dur",
        //   "duration_type",
        // ];

        // updateEventInCalendar(updatedEvent, propsToUpdate);
      });
  };

  // ------------------------------------------------
  // removeEvent
  // ------------------------------------------------
  const removeEvent = () => {
    const eventId = event.value.id;
    store.dispatch("calendar/removeEvent", { id: eventId }).then(() => {
      removeEventInCalendar(eventId);
    });
  };

  // ------------------------------------------------
  // refetchEvents
  // ------------------------------------------------
  const refetchEvents = () => {
    calendarApi.refetchEvents();
  };

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed(
    () => store.state.calendar.selectedCalendars
  );

  watch(selectedCalendars, () => {
    refetchEvents();
  });

  // --------------------------------------------------------------------------------------------------
  // AXIOS: fetchEvents
  // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
  // --------------------------------------------------------------------------------------------------
  const fetchEvents = (info, successCallback) => {
    let user = JSON.parse(localStorage.getItem("userData"));
    // If there's no info => Don't make useless API call
    if (!info) return;
    //console.log("selectedCalendars.value", selectedCalendars.value);

    // Fetch Events from API endpoint
    store
      .dispatch("calendar/fetchEvents", {
        status: selectedCalendars.value,
        user: user.id,
      })
      .then((response) => {
        let data = [];
        //console.log(response.data);
        response.data.data.forEach((el) => {
          if (el.status.id == 1) {
            data.push({
              duration_type: el.duration_type.id,
              dur: el.duration,
              colorName: "success",
              status: el.status.id,
              assign_to:el.assign_to,
              body: el.body,
              title: el.user_id,
              id: el.id,
              replay: el.reply,
              assigner_approve:el.assigner_approve,
              leave_type_id: el.leave_type_id.id,
              user_id:el.user_id,

              start: el.start,
              allDay: false,
            });
          } else if (el.status.id == 0) {
            data.push({
              duration_type: el.duration_type.id,
              dur: el.duration,
              colorName: "primary",
              status: el.status.id,
              user_id:el.user_id,
              assigner_approve:el.assigner_approve,
              assign_to:el.assign_to,
              body: el.body,
              title: el.user_id,
              replay: el.reply,
              id: el.id,
              leave_type_id: el.leave_type_id.id,

              start: el.start,
              allDay: false,
            });
          } else {
            if (el.status.id == 2) {
              data.push({
                duration_type: el.duration_type.id,
                dur: el.duration,
                assign_to:el.assign_to,
                colorName: "danger",
                status: el.status.id,
                replay: el.reply,
                leave_type_id: el.leave_type_id.id,
                body: el.body,
                title: el.user_id,
                id: el.id,
                user_id:el.user_id,
                assigner_approve:el.assigner_approve,

                start: el.start,
                allDay: false,
              });
            }
          }
        });
        //console.log(data);
        successCallback(data);
      })
      .catch(() => {
        Vue.swal({
          title: "حدثت مشكلة في استرجاع البيانات",
          icon: "error",

          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      });
  };

  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------
  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: "dayGridMonth",
    headerToolbar: {
      start: "sidebarToggle, prev,next, title",
      end: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
    },
    events: fetchEvents,

    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: true,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 2,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle

      const colorName = calendarEvent._def.extendedProps.colorName;

      return [
        // Background Color
        `bg-light-${colorName}`,
      ];
    },
    eventClick({ event: clickedEvent }) {
      //console.log("clickedEvent", clickedEvent);
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      let data = grabEventDataFromEventApi(clickedEvent);
      //console.log(" event.value", event.value);
      //console.log(" event.data", data);
      event.value = {
        title: data.title,
        id: data.id,
        status:data.extendedProps.status,
        assign_to:data.extendedProps.assign_to,
        assigner_approve:data.extendedProps.assigner_approve,
        start: data.start,
        duration_type: data.extendedProps.duration_type,
        leave_type_id: data.extendedProps.leave_type_id,
        body: data.extendedProps.body,
        user_id: data.extendedProps.user_id,
        duration: data.extendedProps.dur,
        replay: data.extendedProps.replay,
      };
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true;
    },

    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: "sidebar",
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value;
        },
      },
    },

    dateClick(info) {
      /*
        ! Vue3 Change
        Using Vue.set isn't wordurng for now so we will try to check reactivity in Vue 3 as it can handle this automatically
        ```
        event.value.start = info.date
        ```
      */
      //console.log("info", info);
      event.value = JSON.parse(
        JSON.stringify(Object.assign(event.value, { start: info.date }))
      );
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true;
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }) {
      updateEvent(grabEventDataFromEventApi(droppedEvent));
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      updateEvent(grabEventDataFromEventApi(resizedEvent));
    },

    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? "rtl" : "ltr")),
    rerenderDelay: 350,
  });

  // ------------------------------------------------------------------------

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const isEventHandlerSidebarActive = ref(false);

  const isCalendarOverlaySidebarActive = ref(false);

  return {
    refCalendar,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    event,
    clearEventData,
    addEvent,
    updateEvent,
    removeEvent,
    refetchEvents,
    fetchEvents,

    // ----- UI ----- //
    isEventHandlerSidebarActive,
  };
}
