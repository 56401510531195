<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ eventLocal.id ? 'تفاصيل': 'إضافة' }} إجازة

          </h5>
          <!-- <div>
            <feather-icon
              v-if="eventLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-event'); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div> -->
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-form-group
              v-if="eventLocal.id "
              label="الموظف"
              label-for="event-duration"
            >
           
              <v-select

              v-model="eventLocal.user_id"

:options="employeOptions"
label="label"
:reduce="val => val.value"
disabled
/>
            </b-form-group>

            <!-- Calendar -->
            <validation-provider
              v-if="eventLocal.id "
              #default="validationContext"
              name="leaveType"
              rules="required"
            >

              <b-form-group
                label="نوع الاجازة"
                label-for="leaveTypeupdate"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventLocal.leave_type_id"
                  disabled
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="leaveTypeOptions"
                  label="label"
                  :reduce="val => val.id"
                  :clearable="false"
                >

                  <template #option="{ color, label }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                    />
                    <span> {{ label }}</span>
                  </template>

                  <template #selected-option="{ color, label }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                    />
                    <span> {{ label }}</span>
                  </template>
                </v-select>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              v-else
              #default="validationContext"
              name="leaveType"
              rules="required"
            >

              <b-form-group
                label="نوع الاجازة"
                label-for="leaveType"
                :state="getValidationState(validationContext)"
              >
                <v-select

                  v-model="eventLocal.leave_type_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="leaveTypeOptions"
                  label="label"
                  :reduce="val => val.id"
                  :clearable="false"
                >

                  <template #option="{ color, label }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                    />
                    <span> {{ label }}</span>
                  </template>

                  <template #selected-option="{ color, label }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                    />
                    <span> {{ label }}</span>
                  </template>
                </v-select>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Start Date -->
            <validation-provider
              v-if="eventLocal.id "
              #default="validationContext"
              name="dateupdate"
              rules="required"
            >

              <b-form-group
                label="Start Date"
                label-for="start-date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="eventLocal.start"
                  disabled
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-else
              #default="validationContext"
              name="date"
              rules="required"
            >

              <b-form-group
                label="Start Date"
                label-for="start-date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr

                  v-model="eventLocal.start"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider

              #default="validationContext"
              name="assign_to"
              rules="required"
            >

              <b-form-group
                label="الشخص المكلف"
                label-for="leaveTypeupdate"
                :state="getValidationState(validationContext)"
              >

                <v-select

                  v-model="eventLocal.assign_to"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="employeOptions"
                  label="label"
                  :reduce="val => val.value"
                  :clearable="false"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Guests -->
            <validation-provider
              v-if="eventLocal.id "
              #default="validationContext"
              name="tt"
              rules="required"
            >

              <b-form-group
                v-if="eventLocal.id "
                label="مدة الاجازة"
                label-for="event-duration"
              >
                <b-form-input
                  id="event-duration"
                  v-model="eventLocal.duration"
                  disabled
                  type="number"
                  placeholder="مدة الاجازة"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- duration -->
            <validation-provider
              v-else
              #default="validationContext"
              name="tttime"
              rules="required"
            >
              <b-form-group

                label="مدة الاجازة"
                label-for="event-duration"
              >
                <b-form-input

                  id="event-duration"
                  v-model="eventLocal.duration"
                  type="number"
                  placeholder="مدة الاجازة"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Guests -->
            <validation-provider
              v-if="eventLocal.id "
              #default="validationContext"
              name="typedur"
              rules="required"
            >
              <b-form-group

                label="نوع المدة"
                label-for="add-duration_type"
              >
                <v-select
                  v-model="eventLocal.duration_type"
                  disabled
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="durationTypeOptions"
                  :reduce="val => val.id"

                  label="name"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Guests -->
            <validation-provider

              v-else
              #default="validationContext"
              name="typedurelse"
              rules="required"
            >
              <b-form-group

                label="نوع المدة"
                label-for="add-duration_type"
              >
                <v-select

                  v-model="eventLocal.duration_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="durationTypeOptions"
                  :reduce="val => val.id"

                  label="name"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Guests -->
            <!-- Textarea -->
            <b-form-group
              v-if="eventLocal.id "
              label="تفاصيل"
              label-for="event-body"
            >
              <b-form-textarea
                id="event-body"
                v-model="eventLocal.body"
                disabled
              />
            </b-form-group>
            <b-form-group
              v-else
              label="تفاصيل"
              label-for="event-body"
            >
              <b-form-textarea

                id="event-body"
                v-model="eventLocal.body"
              />
            </b-form-group>

            <v-row v-if="$can('update', 'leaves') && eventLocal.status==0">
              <validation-provider

                v-if="eventLocal.id"
                #default="validationContext"
                name="replay"
                rules="required"
              >

                <b-form-group
                  label="الرد"
                >
                  <v-select

                    v-model="replay.id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="replayOptions"
                    label="label"
                    :reduce="val => val.id"
                    :clearable="false"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                v-if="eventLocal.id && replay.id==1 "
                #default="validationContext"
                name="leaveType"
                rules="required"
              >
                <b-form-group

                  label=" مدة الاجازة المسموح بها"
                  label-for="eupdate-duration_type"
                >
                  <b-form-input

                    id="update-duration_typen"
                    v-model="replay.duration"
                    type="number"
                    placeholder="مدة الاجازة"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>

              </validation-provider>
              <validation-provider
                v-if="eventLocal.id && replay.id==1 "
                #default="validationContext"
                name="updatde-duration"
                rules="required"
              >
                <b-form-group

                  label=" نوع المدة المسموح بها"
                  label-for="updatde-duration"
                >
                  <v-select

                    v-model="replay.duration_type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="durationTypeOptions"
                    :reduce="val => val.id"

                    label="name"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-form-group
                v-if="eventLocal.id && replay.id==2"
                label=" سبب الرفض"
                label-for="event-body"
              >
                <b-form-textarea

                  id="-body"
                  v-model="replay.body"
                />
              </b-form-group>
            </v-row>
            <!-- <v-row v-if="$can('update', 'leaves')" >
            <validation-provider

            v-if="eventLocal.id "
              #default="validationContext"
              name="replay"
              rules="required"
            >

              <b-form-group
                label='الرد'

              >
                <v-select

                  v-model="replay.id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="replayOptions"
                  label="label"
                  :reduce="val => val.id"
                  :clearable="false"

                >

                </v-select>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
            v-if="eventLocal.id && replay.id==1 "
              #default="validationContext"
              name="leaveType"
              rules="required"
            >
            <b-form-group

              label=" مدة الاجازة المسموح بها"
              label-for="eupdate-duration_type"
            >
              <b-form-input

                id="update-duration_typen"
                v-model="replay.duration"
                type="number"
                placeholder="مدة الاجازة"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
          <validation-provider
            v-if="eventLocal.id && replay.id==1 "
              #default="validationContext"
              name="updatde-duration"
              rules="required"
            >
            <b-form-group

              label=" نوع المدة المسموح بها"
              label-for="updatde-duration"
            >
              <v-select

                v-model="replay.duration_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="durationTypeOptions"
                :reduce="val => val.id"

                label="name"

              >

              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group
            v-if="eventLocal.id && replay.id==2"
              label=" سبب الرفض"
              label-for="event-body"

            >
              <b-form-textarea

                id="-body"
                v-model="replay.body"
              />
            </b-form-group>
            </v-row> -->
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-if="eventLocal.id && $can('update', 'leaves') && eventLocal.status==0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                رد
              </b-button>
              <b-button
                v-if="!eventLocal.id"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                إضافة
              </b-button>

            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BAvatar, BFormTextarea, BButton, BFormInvalidFeedback, vRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import useCalendarEventHandler from './useCalendarEventHandler'

export default {
  components: {
    BButton,
    vRow,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      employeOptions: [],
      required,
      email,
      url,
    }
  },
  created() {
    this.getemploye()
  },
  methods: {
    getemploye() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.employeOptions = []
      this.$http.get('/api/v1/get-all-users').then(res => {
        const employe = res.data
        employe.forEach(el => {
          if (el.id != userData.id) {
            this.employeOptions.push({ value: el.id, label: `${el.first_name} ${el.last_name} ` })
          }
        })
        // console.log(this.employeOptions);
      })
    },
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,
      leaveTypeOptions,
      // UI
      onSubmit,
      replay,
      durationTypeOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,

    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm

    const replayOptions = [{
      label: 'قبول', id: 1,
    },
    {
      label: 'رفض', id: 2,
    },
    ]

    return {
      // Add New Event
      replay,
      replayOptions,
      leaveTypeOptions,
      eventLocal,
      calendarOptions,
      onSubmit,
      durationTypeOptions,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
